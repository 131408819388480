<template>
    <div class="login-container">
        <b-container class="d-flex justify-content-center align-items-center min-vh-100">
            <transition name="fade-move" appear>
            <b-card v-if="showCard" class="login-card" border-variant="light">
                <b-card-title class="text-center">Bienvenidos al sistema</b-card-title>
                <b-card-body>
                <b-form @submit.prevent="onSubmit">
                    <b-form-group label="Correo Electrónico" label-for="email-input">
                    <b-form-input
                        id="email-input"
                        type="email"
                        v-model="user.email"
                        required
                        placeholder="Ingresa tu correo electrónico"
                    ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Contraseña" label-for="password-input">
                    <b-form-input
                        id="password-input"
                        type="password"
                        @keydown.enter.prevent="login"
                        v-model="user.password"
                        required
                        placeholder="Ingresa tu contraseña"
                    ></b-form-input>
                    </b-form-group>
                    <b-button
                    type="submit"
                    @click.prevent="login"
                    :disabled="!isFormValid"
                    variant="success"
                    block
                    >
                    Iniciar Sesión
                    </b-button>
                </b-form>
                <b-card-text v-if="error">
                    <b-alert class="mt-4" show dismissible fade variant="danger">{{ error }}</b-alert>
                </b-card-text>
                </b-card-body>
            </b-card>
            </transition>
        </b-container>
    </div>
</template>
  
<script>
    import { mapActions } from "vuex";
    export default {
        data() {
        return {
            email: "",
            password: "",
            error: "",
            user: {
            email: '',
            password: '',
            },
            showCard: false, // Nueva propiedad para controlar la visibilidad del card
        };
        },
        computed: {
        isFormValid() {
            return this.user.email !== '' && this.user.password !== '';
        },
        },
        created() {
        this.checkToken();
        document.title = "B4B";
        },
        mounted() {
        this.showCard = true; // Mostrar el card después de que el componente esté montado
        },
        methods: {
        ...mapActions('auth', {
            _signIn: 'signIn',
            _alreadyAuth: 'getUser',
        }),
        checkToken() {
            if (localStorage.getItem('user-token')) {
            this._alreadyAuth();
            }
        },
        async login() {
            this.error = "";
            await this._signIn(this.user)
            .then((success) => {
                if (success) {
                this.error = success;
                }
            })
            .catch((error) => {
                this.error = error;
            });
        },
        },
    };
</script>
  
<style>
.login-container {
    background-image: url('/image/login-background.jpg'); /* Ruta de tu imagen de fondo */
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
    background-position: center; /* Centra la imagen */
    height: 100vh;
}
.login-card {
    background-color: #ffffff; /* Color de fondo para la tarjeta (blanco) */
    min-width: 600px; /* Ancho máximo de la tarjeta */
    margin: auto;
}
.fade-move-enter-active, .fade-move-leave-active {
    transition: opacity 1s, transform 1s;
}
.fade-move-enter, .fade-move-leave-to {
    opacity: 0;
    transform: translateY(100px);
}
@media (max-width: 768px) {
    .login-card{
        min-width: 300px;
    }
}
</style>
  